// src/components/NotFound.js
import React from "react";
import { motion } from "framer-motion";
import "./NotFound.css";

const NotFound = () => {
  return (
    <div className="page-container-not-found not-found">
      <motion.div
        className="not-found-content"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h1 className="not-found-heading">404 - Page Not Found</h1>
        <p className="not-found-message">
          The page you are looking for does not exist.
        </p>
      </motion.div>
      <motion.div
        className="not-found-animation"
        initial={{ scale: 1 }}
        animate={{ scale: [1, 1.2, 1] }}
        transition={{ repeat: Infinity, duration: 2, ease: "easeInOut" }}
      >
        <svg
          className="magnifying-glass"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx="11" cy="11" r="8" />
          <line x1="21" y1="21" x2="16.65" y2="16.65" />
        </svg>
      </motion.div>
    </div>
  );
};

export default NotFound;
