import React, { useState } from "react";
import { Container, Form, Button, Alert, Card } from "react-bootstrap";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import "./ContactUsPage.css";

const ContactUsPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Simple validation
    if (
      !formData.name ||
      !formData.email ||
      !formData.phone ||
      !formData.message
    ) {
      setError("Please fill out all fields.");
      return;
    }

    if (!executeRecaptcha) {
      setError("Recaptcha not loaded");
      return;
    }

    // Execute reCAPTCHA and get the token
    const recaptchaToken = await executeRecaptcha("submit");

    // Simulate form submission
    console.log("Form submitted:", formData, recaptchaToken);

    fetch("https://formspree.io/f/mldrkojn", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...formData,
        "g-recaptcha-response": recaptchaToken,
      }),
    })
      .then((response) => {
        if (response.ok) {
          setSubmitted(true);
          setError("");
          setFormData({
            name: "",
            email: "",
            phone: "",
            message: "",
          });
        } else {
          setError("Failed to send the message. Please try again later.");
        }
      })
      .catch(() => {
        setError("An error occurred. Please try again later.");
      });
  };

  return (
    <div className="page-container">
      <Container className="my-4">
        <Card className="contact-card shadow mb-4">
          <Card.Body>
            <h2 className="text-center">Contact Us</h2>
            <p className="text-center">
              In case you have any query, please feel free to contact us.
            </p>
            {submitted && (
              <Alert variant="success">We have received your message.</Alert>
            )}
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group controlId="formEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group controlId="formPhone">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="Enter your phone number"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group controlId="formMessage">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  placeholder="Enter your message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                />
              </Form.Group>

              <Button variant="primary" type="submit" className="w-100">
                Send Message
              </Button>
            </Form>
          </Card.Body>
        </Card>

        {/* Map Card */}
        <Card className="map-card shadow">
          <Card.Body>
            <h5 className="text-center">On Map</h5>
            <div
              className="map-container"
              style={{ width: "100%", height: "400px" }}
            >
              {/* Embed Google Maps iframe */}
              <iframe
                title="Google Maps"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14197.084520559696!2d73.7096136!3d27.179214!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396a91548c20d549%3A0xb56126880f7fa35e!2sJAAT%20HOSTEL%20NAGAUR!5e0!3m2!1sen!2sin!4v1726415189271!5m2!1sen!2sin"
                width="100%"
                height="100%"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
              ></iframe>
            </div>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

const ContactUsWrapper = () => (
  <GoogleReCaptchaProvider reCaptchaKey="6Ld2vkQqAAAAAHnpEHEJ_HiSADf2el9g55xBphNZ">
    <ContactUsPage />
  </GoogleReCaptchaProvider>
);

export default ContactUsWrapper;
