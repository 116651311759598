import React from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import "./DonationPage.css"; // Import custom CSS for styling

const DonationPage = () => {
  const handleCopyClick = (text) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          alert("Bank account details copied to clipboard!");
        })
        .catch(() => {
          fallbackCopyTextToClipboard(text);
        });
    } else {
      fallbackCopyTextToClipboard(text);
    }
  };

  const fallbackCopyTextToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.position = "fixed";
    textArea.style.left = "-9999px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand("copy");
      alert("Bank account details copied to clipboard!");
    } catch (err) {
      console.error("Fallback: Unable to copy", err);
      alert("Unable to copy. Please try manually.");
    }

    document.body.removeChild(textArea);
  };

  const handleUPIClick = () => {
    window.location.href =
      "upi://pay?pa=6378662006@ybl&pn=NRM+Jat+Hostel&tn=Donation+to+NRM+JAT+HOSTEL&cu=INR";
  };

  return (
    <div className="page-container">
      <Container className="content text-center">
        <Row className="d-flex align-items-stretch mt-5">
          <h1 className="">Donate</h1>
          <Col md={6} className="d-flex mb-4 mb-md-0">
            {" "}
            {/* Add mb-4 for spacing in mobile view */}
            <Card className="donation-card flex-fill">
              <Card.Header>Bank Account Details</Card.Header>
              <Card.Body>
                <Card.Text>
                  Name on Account:{" "}
                  <b>Shri Nathu Ram Mirdha Gramothan Charitable Trust</b>
                  <br />
                  Account Number: <b>10280100020429</b>
                  <br />
                  IFSC Code: <b>BARB0NAGAUR</b>
                  <br />
                  Bank Name: <b>Bank Of Baroda</b>
                  <br />
                  Branch: <b>NAGAUR</b>
                </Card.Text>
                <Button
                  variant="primary"
                  onClick={() =>
                    handleCopyClick(
                      "Name on Account: Shri Nathu Ram Mirdha Gramothan Charitable Trust\nAccount Number: 10280100020429\nIFSC Code: BARB0NAGAUR\nBank Name: Bank Of Baroda\nBranch: Nagaur"
                    )
                  }
                >
                  Copy Details
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} className="d-flex">
            <Card className="donation-card flex-fill">
              <Card.Header>Donate via UPI</Card.Header>
              <Card.Body>
                <Card.Text>
                  UPI ID: <b>6378662006@ybl</b>
                </Card.Text>
                <Button variant="success" onClick={handleUPIClick}>
                  Donate with UPI
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DonationPage;
