import React from "react";
import { Container } from "react-bootstrap";
import "./AboutPage.css"; // Ensure to create this CSS file for custom styling

const AboutPage = () => {
  return (
    <div className="page-container">
      <Container className="content">
        <h1 className="content-center">About Us</h1>
        <p>
          On December 13, 2019, the Jat hostel located at Mansar Chauraha began
          operations. The grand building of the hostel was constructed with the
          help of its executive members and generous philanthropists from the
          society. This hostel provides a golden opportunity for students from
          remote rural areas and the underprivileged sections of society, who
          are unable to complete their studies due to financial or other
          reasons, to move ahead in their education. The hostel is operated by
          active members.
        </p>
        <p>
          Since its inception, many students have brightened their futures by
          staying in the hostel. Participation of the Hostel in Social
          Activities:- (NRM) Nathuram Mirdha Jat Hostel Executive and Hostel
          Students have participated in many social activities which are as
          follows:
        </p>
        <ul>
          <li>
            In Nathuram Mirdha Hostel, great personalities and social reformers
            of the society are remembered from time to time through their
            anniversaries and tributes, and students are inspired to follow
            their ideals.
          </li>
          <li>
            The hostel administration makes continuous efforts to encourage the
            youth of the Jat community to stay away from the current trend of
            substance abuse and instead focus on education to brighten their
            future.
          </li>
          <li>
            To eliminate certain social evils ingrained in the community, the
            hostel administration motivates students, such as efforts to stop
            death feasts, DJ music, and the dowry system in wedding ceremonies.
          </li>
          <li>
            Blood donation camps are organized in the hostel from time to time,
            and when needed, students go to the city's Jawaharlal Nehru
            Government Hospital to donate blood.
          </li>
          <li>
            The hostel's executive committee arranges free accommodation and
            food for students participating in various competitive exams held in
            Nagaur.
          </li>
          <li>
            Students of the hostel regularly conduct cleanliness drives in and
            around the hostel premises.
          </li>
          <li>
            During the annual fair held at Veer Teja Temple in Kharnal, students
            volunteer their services.
          </li>
        </ul>
        <p>
          Academic Achievements of the Hostel: Students from the hostel have
          achieved success in various exams through rigorous and dedicated
          study. Hostel students continuously prepare for various competitive
          exams and frequently get selected in different examinations.
        </p>
        <p>
          Currently, many students from the hostel are serving in various
          departments of the central and state governments. From December 13,
          2019, to March 2024, a total of 84 students have been finally selected
          for government service.
        </p>
      </Container>
    </div>
  );
};

export default AboutPage;
