import React, { useEffect, useState } from "react";
import "./ScrollGuidePopup.css"; // Import the CSS file for styles

const ScrollGuidePopup = ({ onClose }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia("(max-width: 768px)").matches);
    };

    handleResize(); // Check initial screen size
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.classList.contains("scroll-guide-popup")) {
        onClose(); // Close popup if clicked outside the content
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [onClose]);

  if (!isMobile) return null; // Do not render on non-mobile devices

  return (
    <div className="scroll-guide-popup">
      <div className="popup-content">
        <p className="popup-text">Scroll horizontally to see more details.</p>
        <p className="popup-text">
          अधिक विवरण देखने के लिए दाएं से बाएं स्क्रॉल करें
        </p>
        <div className="animation-wrapper">
          <img
            src="./imgs/swipe-left.png" // Replace with your animated finger image URL
            alt="Scroll Guide"
            className="scroll-animation"
          />
        </div>
        <button className="close-btn" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default ScrollGuidePopup;
