import React, { useEffect, useState } from "react";
import { Table, Container } from "react-bootstrap";
import PaginationComponent from "./PaginationComponent"; // Import the Pagination component
import "@fortawesome/fontawesome-free/css/all.min.css"; // Import Font Awesome
import "./App.css"; // Ensure you import the CSS file for styles

const SelectedCandidatesPage = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(15); // Updated to 15 items per page
  const [sortColumn, setSortColumn] = useState("srno"); // Default sort column
  const [sortOrder, setSortOrder] = useState("asc"); // Default sort order

  useEffect(() => {
    fetch("./selected_candidates.json") // Update to your data source
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  // Sorting logic
  const handleSort = (column) => {
    const newSortOrder =
      sortColumn === column && sortOrder === "asc" ? "desc" : "asc";
    setSortColumn(column);
    setSortOrder(newSortOrder);
  };

  const sortedData = [...data].sort((a, b) => {
    if (a[sortColumn] < b[sortColumn]) return sortOrder === "asc" ? -1 : 1;
    if (a[sortColumn] > b[sortColumn]) return sortOrder === "asc" ? 1 : -1;
    return 0;
  });

  // Get current candidates
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);

  // Pagination logic
  const totalPages = Math.ceil(data.length / itemsPerPage);

  return (
    <div className="page-container">
      <Container className="my-4">
        <h2 className="text-center">Selected Candidates</h2>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th
                className="srno-column"
                onClick={() => handleSort("srno")}
                style={{ cursor: "pointer" }}
              >
                Sr No
                {sortColumn === "srno" &&
                  (sortOrder === "asc" ? (
                    <i className="fas fa-sort-up ml-2"></i>
                  ) : (
                    <i className="fas fa-sort-down ml-2"></i>
                  ))}
              </th>
              <th
                onClick={() => handleSort("name")}
                style={{ cursor: "pointer" }}
              >
                Name
                {sortColumn === "name" &&
                  (sortOrder === "asc" ? (
                    <i className="fas fa-sort-up ml-2"></i>
                  ) : (
                    <i className="fas fa-sort-down ml-2"></i>
                  ))}
              </th>
              <th
                onClick={() => handleSort("posts")}
                style={{ cursor: "pointer" }}
              >
                Post
                {sortColumn === "posts" &&
                  (sortOrder === "asc" ? (
                    <i className="fas fa-sort-up ml-2"></i>
                  ) : (
                    <i className="fas fa-sort-down ml-2"></i>
                  ))}
              </th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((candidate) => (
              <tr key={candidate.srno}>
                <td className="srno-column">{candidate.srno}</td>
                <td>{candidate.name}</td>
                <td>{candidate.posts}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        <PaginationComponent
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </Container>
      <p className="text-center lead">Last Updated: 16/09/2024</p>
    </div>
  );
};

export default SelectedCandidatesPage;
