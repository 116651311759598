import React from "react";
import { Pagination } from "react-bootstrap";

const formatPageNumber = (number) => {
  return number < 10 ? `${number}` : `${number}`;
};

const PaginationComponent = ({ currentPage, totalPages, onPageChange }) => {
  const maxPagesToShow = 5; // Number of page numbers to show

  // Calculate page range to display
  const halfPagesToShow = Math.floor(maxPagesToShow / 2);
  const startPage = Math.max(1, currentPage - halfPagesToShow);
  const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

  // Adjust startPage if there are not enough pages to display the full range
  const adjustedStartPage = Math.max(1, endPage - maxPagesToShow + 1);

  const pageNumbers = [];
  for (let i = adjustedStartPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  return (
    <Pagination className="d-flex justify-content-center mt-3">
      <Pagination.Prev
        disabled={currentPage === 1}
        onClick={() => onPageChange(currentPage - 1)}
      />
      {pageNumbers.map((number) => (
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => onPageChange(number)}
        >
          {formatPageNumber(number)}
        </Pagination.Item>
      ))}
      <Pagination.Next
        disabled={currentPage === totalPages}
        onClick={() => onPageChange(currentPage + 1)}
      />
    </Pagination>
  );
};

export default PaginationComponent;
