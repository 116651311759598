import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Footer.css"; // Import the CSS file for styles

const Footer = () => {
  return (
    <footer className="footer-custom py-3">
      <Container>
        <Row>
          <Col md={12}>
            <p>
              © 2024 Shri Nathu Ram Mirdha Gramothan Charitable Trust. All
              rights reserved.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
