import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Modal } from "react-bootstrap";
import "./GalleryPage.css"; // Import CSS

const GalleryPage = () => {
  const [images, setImages] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  useEffect(() => {
    // Fetch gallery from JSON file
    fetch("./gallery.json")
      .then((response) => response.json())
      .then((data) => setImages(data))
      .catch((error) => console.error("Error fetching images:", error));
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = (src) => {
    setSelectedImage(src);
    setShow(true);
  };

  return (
    <div className="page-container">
      <Container className="my-4">
        <h2 className="text-center">Gallery</h2>
        <Row>
          {images.map((image, index) => (
            <Col md={4} sm={6} xs={12} key={index} className="mb-4">
              <Card
                style={{ cursor: "pointer" }}
                onClick={() => handleShow(image.src)}
              >
                <Card.Img
                  variant="top"
                  src={image.src}
                  alt={`Gallery ${index + 1}`}
                />
                <Card.Body>
                  <Card.Text className="text-center">
                    {image.description}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>

        {/* Full Screen Modal for Image Zoom */}
        <Modal
          show={show}
          onHide={handleClose}
          dialogClassName="modal-dialog"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <img src={selectedImage} alt="Zoomed" />
          </Modal.Body>
        </Modal>
      </Container>
    </div>
  );
};

export default GalleryPage;
