import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./Navbar";
import HomePage from "./Homepage";
import AboutPage from "./AboutPage";
import Footer from "./Footer";
import ContributorsPage from "./ContributorsPage";
import GalleryPage from "./GalleryPage";
import SelectedCandidatesPage from "./SelectedCandidatesPage";
import DonationPage from "./DonationPage";
import NotFound from "./NotFound";
import ContactUsPage from "./ContactUsPage";

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contributors" element={<ContributorsPage />} />
        <Route path="/gallery" element={<GalleryPage />} />
        <Route
          path="/selected-candidates"
          element={<SelectedCandidatesPage />}
        />
        <Route path="/donate" element={<DonationPage />} />
        <Route path="/contact-us" element={<ContactUsPage />} />

        {/* Catch-all route for 404 */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
