import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Navbar.css"; // Import the CSS file for styles

const NavigationBar = () => {
  return (
    <Navbar expand="lg" className="navbar-custom">
      <Navbar.Brand as={Link} to="/" className="flowing-text">
        NRM Jat Hostel
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mx-auto">
          <Nav.Link as={Link} to="/">
            Home
          </Nav.Link>
          <Nav.Link as={Link} to="/about">
            About
          </Nav.Link>
          <Nav.Link as={Link} to="/contributors">
            Bhamasha
          </Nav.Link>
          <Nav.Link as={Link} to="/gallery">
            Gallery
          </Nav.Link>
          <Nav.Link as={Link} to="/selected-candidates">
            Selected Candidates
          </Nav.Link>
          <Nav.Link as={Link} to="/contact-us">
            ContactUs
          </Nav.Link>
          <Nav.Link as={Link} to="/Donate">
            Donate
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavigationBar;
